import React from 'react';
import { useEffect, useState } from 'react'
import Header from './Header'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './Home.css';
import Cookies from 'js-cookie';

const ssoUrl = "https://onepassport.alpiq.io/login?nocookie&redirect=" + window.location.origin + "/api/auth/ssoHandler"

export default function Home() {
    const [username, setUsername] = useState()
    const [token, ] = useState(Cookies.get('token'))
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [showAvailabilities, setShowAvailabilities] = useState(false)
    const [showFundamentals, setshowFundamentals] = useState(false)

    useEffect(() => {
        if (!token) {
            fetch("/api/auth/ssoHandler")
        }
        fetch('/api/auth/getUserRoles',
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            }
        ).then(response => {
            if (response.status === 200) {
                setIsLoggedIn(true)
                return response.json()
            } else {
                window.location.replace(ssoUrl);
            }
        })
            .then(dataDict => {
                setUsername(dataDict['UserId'])
                setShowAvailabilities(dataDict['Roles'].some(ai => ["G_SEC_PFM_Availabilities", "G_SEC_PFM_AllApps"].includes(ai)))
                setshowFundamentals(dataDict['Roles'].some(ai => ["G_SEC_PFM_Fundamentals", "G_SEC_PFM_AllApps"].includes(ai)))
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [token])

    const urlPowerTable = "availabilities/power"
    const urlPowerChart = "availabilities/power/chart"
    const urlGasTable = "availabilities/gas/"
    const urlGasChart = "availabilities/gas/chart/"
    const urlFundamentals = "fundamentals/"

    return (
        <div className="root_container">
            <Header username={username} />
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                <div style={isLoggedIn ? { display: "block", position: "absolute", bottom: "50%" } : { display: "none" }} >
                    <Container>
                        <Row>
                            {showAvailabilities ?
                                <>
                                    <Col>
                                        <a href={urlPowerTable} className="appCard">
                                            <img className="appIcon" src="/icons/power_table.png" alt="logo" />
                                            <span className="appTitle">REMIT Power Table</span>
                                        </a>
                                    </Col>



                                    < Col >
                                        <a href={urlPowerChart} className="appCard">
                                            <img className="appIcon" src="/icons/power_chart.png" alt="logo" />
                                            <span className="appTitle">REMIT Power Chart</span>
                                        </a>
                                    </Col>

                                    <Col>
                                        <a href={urlGasTable} className="appCard">
                                            <img className="appIcon" src="/icons/gas_table.png" alt="logo" />
                                            <span className="appTitle">REMIT Gas Table</span>
                                        </a>
                                    </Col>

                                    <Col>
                                        <a href={urlGasChart} className="appCard">
                                            <img className="appIcon" src="/icons/gas_chart.png" alt="logo" />
                                            <span className="appTitle">REMIT Gas Chart</span>
                                        </a>
                                    </Col>
                                </>
                                :
                                null

                            }
                            {showFundamentals ?

                                <Col>
                                    <a href={urlFundamentals} className="appCard">
                                        <img className="appIcon" src="/icons/fundamentals.png" alt="logo" />
                                        <span className="appTitle">Power Models Dashboard</span>
                                    </a>
                                </Col>
                                :
                                null
                            }

                        </Row>


                    </Container>
                </div>
            </div>
        </div >
    )
}
